export const gad = [
    {
        type: "radiogroup",
        name: "GAD71_A",
        title: "Over the last two weeks, how often have you been bothered by feeling nervous, anxious, or on edge?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: true // Set to 'true' to show the question based on the Universe condition
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GAD72_A",
        title: "Over the last two weeks, how often have you been bothered by not being able to stop or control worrying?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: true // Set to 'true' to display the question based on the Universe condition
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GAD73_A",
        title: "Over the last two weeks, how often have you been bothered by worrying too much about different things?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: true // Set to 'true' to display the question based on the Universe condition
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GAD74_A",
        title: "Over the last two weeks, how often have you been bothered by trouble relaxing?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: true // Set to 'true' to display the question based on the Universe condition
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GAD75_A",
        title: "Over the last two weeks, how often have you been bothered by being so restless that it's hard to sit still?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: true // Set to 'true' to display the question based on the Universe condition
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GAD75_A",
        title: "Over the last two weeks, how often have you been bothered by being so restless that it's hard to sit still?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: true // Set to 'true' to display the question based on the Universe condition
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GAD76_A",
        title: "Over the last two weeks, how often have you been bothered by becoming easily annoyed or irritable?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: true // Set to 'true' to display the question based on the Universe condition
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GAD77_A",
        title: "Over the last two weeks, how often have you been bothered by feeling afraid as if something awful might happen?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: true // Set to 'true' to display the question based on the Universe condition
        // Skip logic can be implemented here if needed.
    }
];