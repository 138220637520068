export const phq = [
    {
        type: "radiogroup",
        name: "PHQ81_A",
        title: "Over the last two weeks, how often have you been bothered by little interest or pleasure in doing things?",
        isRequired: true,
        colCount: 1, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PHQ82_A",
        title: "Over the last two weeks, how often have you been bothered by feeling down, depressed, or hopeless?",
        isRequired: true,
        colCount: 1, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PHQ83_A",
        title: "Over the last two weeks, how often have you been bothered by trouble falling or staying asleep, or sleeping too much?",
        isRequired: true,
        colCount: 1, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PHQ84_A",
        title: "Over the last two weeks, how often have you been bothered by feeling tired or having little energy?",
        isRequired: true,
        colCount: 1, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PHQ85_A",
        title: "Over the last two weeks, how often have you been bothered by poor appetite or overeating?",
        isRequired: true,
        colCount: 1, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PHQ86_A",
        title: "Over the last two weeks, how often have you been bothered by feeling bad about yourself, or that you are a failure, or have let yourself or your family down?",
        isRequired: true,
        colCount: 1, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PHQ87_A",
        title: "Over the last two weeks, how often have you been bothered by trouble concentrating on things, such as reading the newspaper or watching television?",
        isRequired: true,
        colCount: 1, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PHQ88_A",
        title: "Over the last two weeks, how often have you been bothered by moving or speaking so slowly that other people could have noticed? Or the opposite, being so fidgety or restless that you have been moving around a lot more than usual?",
        isRequired: true,
        colCount: 1, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Several days"
            },
            {
                value: 3,
                text: "More than half the days"
            },
            {
                value: 4,
                text: "Nearly every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },

];